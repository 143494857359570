import React from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {graphql, Link} from 'gatsby';
import Seo from '../components/seo';
import InstagramIcon from "../assets/images/icons/logo_Instagram_Glyph_Gradient.png";

export default function Contact({data: {contactUs}}) {

	return <Page orientation="portrait">

		<Seo remoteMetaData={contactUs} title={contactUs.titleSEO} />


		<div className="game-box">

			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://www.instagram.com/keenkidsglobal/" target="_blank"
							   className="ico-social-instagram-wrap">
								<img src={InstagramIcon} alt="Instagram logo"
									 className="ico-social-instagram"/>
							</a>
							<a href="https://shop.keenkids.com.au" target="_blank"
							   className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>


					<h1>{contactUs.header}</h1>

					<Markdown>{contactUs.bodyText}</Markdown>
					<div>Business name:</div>
					<p><strong>{contactUs.BusinessName}</strong></p>
					<div>Email:</div>
					<p><a href={"mailto:" + contactUs.Email}>{contactUs.Email}</a></p>
					<div>Phone:</div>
					<p>{contactUs.Phone}</p>
					<div>Address:</div>
					<Markdown>{contactUs.Address}</Markdown>
					<p>ABN: {contactUs.ABN}</p>


					<Sponsor/>
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
		</div>
		{/* / .game-box */}
	</Page>;
}

export const query = graphql`
    query {
		contactUs {
      		header
      		bodyText
			active
			ABN
			Address
			BusinessName
			Email
			Phone
			active
			published
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}			
			}
		}
    }
`;
